button {
  .MuiIcon-root {
    line-height: 18px;
  }
}

@font-face {
  font-family: "Dorado";
  src: local("Dorado"), url(./assets//fonts//dorado-icons.woff) format("woff");
}

.scrollbar {
  overflow: overlay
}

.scrollbar::-webkit-scrollbar {
  background-color: rgba(0,0,0,0);
  width: 16px;
  height: 16px;
  z-index: 999999;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0,0,0,0);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0);
  border-radius:16px;
  border:0px solid #fff;
}

.scrollbar::-webkit-scrollbar-button {
  display:none;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
  border:4px solid #fff;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
    border:4px solid #f4f4f4
}

@import './icons.scss';
