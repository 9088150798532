.change-password-container {
  height: 100%
}

.change-password-card {
  width: 95%;
  margin-top: -2%;
}

@media screen and (min-width: 600px) {
  .change-password-card {
    width: 60%;
    margin-top: -2%;
  }
}

@media screen and (min-width: 960px) {
  .change-password-card {
    width: 40%;
    margin-top: -2%;
  }
}

@media screen and (min-width: 1280px) {
  .change-password-card {
    width: 30%;
    margin-top: -2%;
  }
}