$icon-prefix: "dor-icon";
$line-height: 1.5 !default; // used in reboot (may not be useful for us)
$animation-large-time: 1s !default;

i,
.#{$icon-prefix} {
  font-family: "Dorado";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: $line-height;
  //height: 1em;
  //width: 1em;
  //display: inline-block;
  //vertical-align: middle;
  //box-sizing: border-box;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// fixed width ------------------------
.#{$icon-prefix}__fixed-width {
  width: (18em / 14);
  text-align: center;
}

// stacked ------------------------
.#{$icon-prefix}__stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.#{$icon-prefix}__stack-1x,
.#{$icon-prefix}__stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 1em;
}
.#{$icon-prefix}__stack-1x {
  line-height: inherit;
}
.#{$icon-prefix}__stack-2x {
  font-size: 2em;
}

// animations ------------------------
.#{$icon-prefix}__is-spin {
  @extend .#{$icon-prefix}__fixed-width;
  display: inline-block;
  animation: icon-spin $animation-large-time infinite linear;
}

.#{$icon-prefix}__is-pulse {
  @extend .#{$icon-prefix}__fixed-width;
  display: inline-block;
  animation: icon-spin $animation-large-time infinite steps(8);
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

// custom icons -----------------
.#{$icon-prefix}_spinner {
  position: relative;
  height: 1em;
  &:after,
  &:before {
    padding: 0 !important;
    margin: 0 !important;
    width: 1em;
    height: 1em;
    display: inline-block;
    border: 0.2em solid;
    border-radius: 50%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    @extend .dor-icon__is-spin;
    border-left-color: transparent;
    border-right-color: transparent;
  }
  &:before {
    opacity: 0.35;
  }
}

// icons ------------------------
.#{$icon-prefix}_circle-warning:before {
  content: "\e600";
}
.#{$icon-prefix}_warning:before {
  content: "\e601";
}
.#{$icon-prefix}_exclamation:before {
  content: "\e602";
}
.#{$icon-prefix}_printer:before {
  content: "\e603";
}
.#{$icon-prefix}_user:before {
  content: "\e604";
}
.#{$icon-prefix}_user-md:before {
  content: "\e605";
}
.#{$icon-prefix}_search:before {
  content: "\e606";
}
.#{$icon-prefix}_envelope:before {
  content: "\e607";
}
.#{$icon-prefix}_tick:before {
  content: "\e608";
}
.#{$icon-prefix}_cross:before {
  content: "\e609";
}
.#{$icon-prefix}_download:before {
  content: "\e60a";
}
.#{$icon-prefix}_circle-cross:before {
  content: "\e60b";
}
.#{$icon-prefix}_menu:before {
  content: "\e60c";
}
.#{$icon-prefix}_desktop:before {
  content: "\e60d";
}
.#{$icon-prefix}_laptop:before {
  content: "\e60e";
}
.#{$icon-prefix}_tablet:before {
  content: "\e60f";
}
.#{$icon-prefix}_mobile:before {
  content: "\e610";
}
.#{$icon-prefix}_phone:before {
  content: "\e611";
}
.#{$icon-prefix}_thumbs-up:before {
  content: "\e612";
}
.#{$icon-prefix}_thumbs-down:before {
  content: "\e613";
}
.#{$icon-prefix}_circle-o-notch:before {
  content: "\e614";
}
.#{$icon-prefix}_list:before {
  content: "\e615";
}
.#{$icon-prefix}_clock:before {
  content: "\e616";
}
.#{$icon-prefix}_gear:before {
  content: "\e617";
}
.#{$icon-prefix}_question:before {
  content: "\e618";
}
.#{$icon-prefix}_share:before {
  content: "\e619";
}
.#{$icon-prefix}_heart:before {
  content: "\e61a";
}
.#{$icon-prefix}_heart-broken:before {
  content: "\e61b";
}
.#{$icon-prefix}_stethoscope:before {
  content: "\e61c";
}
.#{$icon-prefix}_hospital:before {
  content: "\e61d";
}
.#{$icon-prefix}_ambulance:before {
  content: "\e61e";
}
.#{$icon-prefix}_medicines:before {
  content: "\e61f";
}
.#{$icon-prefix}_medkit:before {
  content: "\f0fa";
}
.#{$icon-prefix}_h-square:before {
  content: "\e620";
}
.#{$icon-prefix}_circle-heart:before {
  content: "\e621";
}
.#{$icon-prefix}_heartbeat:before {
  content: "\e622";
}
.#{$icon-prefix}_syringe:before {
  content: "\e623";
}
.#{$icon-prefix}_wheelchair:before {
  content: "\e624";
}
.#{$icon-prefix}_walk:before {
  content: "\e625";
}
.#{$icon-prefix}_car:before {
  content: "\e626";
}
.#{$icon-prefix}_location:before {
  content: "\e627";
}
.#{$icon-prefix}_location2:before {
  content: "\e628";
}
.#{$icon-prefix}_file:before {
  content: "\e629";
}
.#{$icon-prefix}_file-pdf:before {
  content: "\e62a";
}
.#{$icon-prefix}_file-text:before {
  content: "\e62b";
}
.#{$icon-prefix}_file-bill:before {
  content: "\e62c";
}
.#{$icon-prefix}_square:before {
  content: "\e62d";
}
.#{$icon-prefix}_square-check:before {
  content: "\e62e";
}
.#{$icon-prefix}_circle:before {
  content: "\e62f";
}
.#{$icon-prefix}_circle-check:before {
  content: "\e630";
}
.#{$icon-prefix}_angle-down:before {
  content: "\e631";
}
.#{$icon-prefix}_angle-left:before {
  content: "\e632";
}
.#{$icon-prefix}_angle-right:before {
  content: "\e633";
}
.#{$icon-prefix}_angle-up:before {
  content: "\e634";
}
.#{$icon-prefix}_arrow-down:before {
  content: "\e635";
}
.#{$icon-prefix}_arrow-left:before {
  content: "\e636";
}
.#{$icon-prefix}_arrow-right:before {
  content: "\e637";
}
.#{$icon-prefix}_arrow-up:before {
  content: "\e638";
}
.#{$icon-prefix}_caret-down:before {
  content: "\e639";
}
.#{$icon-prefix}_caret-left:before {
  content: "\e63a";
}
.#{$icon-prefix}_caret-right:before {
  content: "\e63b";
}
.#{$icon-prefix}_caret-up:before {
  content: "\e63c";
}
.#{$icon-prefix}_linkedin:before {
  content: "\e63d";
}
.#{$icon-prefix}_logo:before {
  content: "\e63e";
}
.#{$icon-prefix}_logo-small:before {
  content: "\e63f";
}
.#{$icon-prefix}_twitter:before {
  content: "\e640";
}
.#{$icon-prefix}_facebook:before {
  content: "\e641";
}
.#{$icon-prefix}_google-plus:before {
  content: "\e642";
}
.#{$icon-prefix}_pinterest:before {
  content: "\e643";
}
.#{$icon-prefix}_circle-dot-check:before {
  content: "\e644";
}
.#{$icon-prefix}_square-check-tick:before {
  content: "\e645";
}
.#{$icon-prefix}_star:before {
  content: "\e646";
}
.#{$icon-prefix}_man:before {
  content: "\f005";
}
.#{$icon-prefix}_woman:before {
  content: "\f006";
}
.#{$icon-prefix}_external-link:before {
  content: "\f007";
}
.#{$icon-prefix}_popup:before {
  content: "\f008";
}
.#{$icon-prefix}_black-triangle:before {
  content: "\f009";
}
.#{$icon-prefix}_blog:before {
  content: "\f022";
}
.#{$icon-prefix}_user-sym-check:before {
  content: "\f234";
}
.#{$icon-prefix}_plus:before {
  content: "\f067";
}
.#{$icon-prefix}_minus:before {
  content: "\f068";
}
.#{$icon-prefix}_refresh:before {
  content: "\f021";
}
.#{$icon-prefix}_info-circle:before {
  content: "\f05a";
}
.#{$icon-prefix}_expand:before {
  content: "\f065";
}
.#{$icon-prefix}_compress:before {
  content: "\f066";
}
.#{$icon-prefix}_vegetables-salad:before {
  content: "\f0c8";
}
.#{$icon-prefix}_beer-glass-foam:before {
  content: "\f0c9";
}
.#{$icon-prefix}_biking-person:before {
  content: "\f0ca";
}
.#{$icon-prefix}_fruit-apple:before {
  content: "\f0cb";
}
.#{$icon-prefix}_fruit-lemon:before {
  content: "\f0cc";
}
.#{$icon-prefix}_laboratory-test-blood-sugar:before {
  content: "\f0cd";
}
.#{$icon-prefix}_meal-can:before {
  content: "\f0ce";
}
.#{$icon-prefix}_medical-instrument-stethoscope:before {
  content: "\f0cf";
}
.#{$icon-prefix}_monitor-heart-beat:before {
  content: "\f0d0";
}
.#{$icon-prefix}_spirits-glass:before {
  content: "\f0d1";
}
.#{$icon-prefix}_trekking-person:before {
  content: "\f0d2";
}
.#{$icon-prefix}_vegetables-plate:before {
  content: "\f0d3";
}
.#{$icon-prefix}_wine-glass:before {
  content: "\f0d4";
}
.#{$icon-prefix}_potato:before {
  content: "\f0d5";
}
.#{$icon-prefix}_instagram:before {
  content: "\f16d";
}
.#{$icon-prefix}_gauge:before {
  content: "\f16e";
}
.#{$icon-prefix}_phone-emergency:before {
  content: "\f16f";
}
.#{$icon-prefix}_sliders:before {
  content: "\f1de";
}
.#{$icon-prefix}_nurse:before {
  content: "\f1df";
}
