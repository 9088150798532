.login-container {
  height: 100%
}

.login-card {
  width: 95%;
  margin-top: -40%;
}

@media screen and (min-width: 600px) {
  .login-card {
    width: 60%;
    margin-top: -10%;
  }
}

@media screen and (min-width: 960px) {
  .login-card {
    width: 40%;
    margin-top: -10%;
  }
}

@media screen and (min-width: 1280px) {
  .login-card {
    width: 30%;
    margin-top: -10%;
  }
}