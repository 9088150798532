.WAMuiChipInput-inputRoot-2.WAMuiChipInput-filled-7{
  padding-top: 0px !important;
  max-height: 120px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  min-height: 48px !important;
}
.WAMuiChipInput-chipContainer-4{
  min-height: 42px !important;
  max-height: 120px !important;
  overflow: hidden  !important;
  overflow-y: auto !important;
  align-items: center !important;
}
.WAMuiChipInput-chip-16 {
  margin: 10px 5px !important;
}
.WAMuiChipInput-underline-14::before{
   content: none !important;
}
.MuiInputBase-input-45{
  padding:  0px !important;
}
.MuiChip-root-52{
    height: 25px !important;
}
.MuiSvgIcon-root-77{
    width: 0.8em !important;
}
.MuiFilledInput-underline-23::before{
   display: none !important
}
.MuiFilledInput-underline-23.MuiFilledInput-error-28::after{
  display: none !important;
}
.MuiFilledInput-underline-23:after{
  display: none !important;
}
.MuiChip-root {
  background-color: #c0bcbc !important;
}