.container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.main-notes-container{
  background-color: #fff;
  padding:20px; 
  width: 80%;
  margin: auto;
}

.sub-notes-container{
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
}

.input-box{
  min-width: 300px !important;
  flex:1 !important;
  margin-right: 5px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.btn-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 15px 0px;
}



.textarea-box{
  max-width: 100%;
}
