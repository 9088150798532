.apps-container {
  padding: 0% 8%;
  width: 100%;
}

.apps-heading.MuiGrid-item {
  margin-top: 3%;

  .MuiTypography-root.MuiTypography-h5 {
    font-weight: bold;
    font-size: 19px;
    color: #0d2247;
  }
}
